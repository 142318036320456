import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomGradient from '../lib/generate/gradient'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'
import { toPx } from '../lib/util'

const Main = ({ gradient, container }) => {
  const {
    backgroundImage,
    backgroundColor,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
    fontStyle,
    fontFamily,
    textTransform,
    textAlign
  } = gradient

  const { text, ...restContainer } = container

  const typography = {
    fontSize: toPx(fontSize),
    fontWeight,
    lineHeight,
    letterSpacing: toPx(letterSpacing),
    fontStyle,
    fontFamily,
    textTransform,
    textAlign
  }

  const textGradient = {
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundColor,
    backgroundImage: backgroundImage
  }

  return (
    <div
      css={{
        padding: 20,
        minHeight: '16rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...restContainer
      }}
    >
      <h1 css={{ width: '100%', ...typography, ...textGradient }}>{text}</h1>
    </div>
  )
}

const GridItem = ({ gradient, ...props }) => {
  const {
    backgroundImage,
    backgroundColor,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
    fontStyle,
    textTransform,
    textAlign,
    fontFamily
  } = gradient

  const typography = {
    fontSize: toPx(fontSize),
    fontWeight,
    lineHeight,
    letterSpacing: toPx(letterSpacing),
    fontStyle,
    textTransform,
    textAlign,
    fontFamily
  }
  return (
    <div
      css={{
        width: '25%',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '16px',
        height: '19vh',
        backgroundColor: backgroundColor
      }}
      {...props}
    >
      <a
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          height: '100%',
          width: '100%',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          ...gradient,
          ...typography
        }}
        {...props}
        children="Aa"
      />
    </div>
  )
}

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(20)
      .fill(0)
      .map((_, i) => {
        const baseGradient = randomGradient()
        const gradientWithPins = { ...baseGradient, ...pins }
        const gradient = {
          ...gradientWithPins,
          backgroundImage: randomGradient.toBackgroundImage(gradientWithPins)
        }

        return (
          <GridItem
            key={i}
            onClick={() => onSelect(gradient)}
            gradient={gradient}
          />
        )
      })}
  </Flex>
)

const Sidebar = ({
  onChange,
  value,
  container,
  onChangeContainer,
  pins,
  onPin
}) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }
  const changeContainer = key => e => {
    console.log(e.target.value)
    onChangeContainer(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Text Gradient</SidebarHeading>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.degrees}
          onClick={() => onPin('degrees', value.degrees)}
        />
        <Label>Degrees</Label>
        <Input
          min={0}
          max={359}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.degrees)}
          type="range"
          onChange={changeValue('degrees')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.degrees}
        />
      </Flex>
      <Color
        value={value.startColor}
        onChange={changeValue('startColor')}
        active={pins.startColor}
        onLock={() => onPin('startColor', value.startColor)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.startColorHinting}
          onClick={() => onPin('startColorHinting', value.startColorHinting)}
        />
        <Label>Hinting</Label>
        <Input
          type="range"
          min={0}
          max={100}
          width={1}
          mr={2}
          value={value.startColorHinting}
          onChange={changeValue('startColorHinting')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.startColorHinting + '%'}
        />
      </Flex>
      <Color
        value={value.endColor}
        onChange={changeValue('endColor')}
        active={pins.endColor}
        onLock={() => onPin('endColor', value.endColor)}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.endColorHinting}
          onClick={() => onPin('endColorHinting', value.endColorHinting)}
        />
        <Label>Hinting</Label>
        <Input
          type="range"
          width={1}
          mr={2}
          min={0}
          max={100}
          value={value.endColorHinting}
          onChange={changeValue('endColorHinting')}
        />
        <Span
          width="80px"
          textAlign="right"
          fontSize={0}
          children={value.endColorHinting + '%'}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 fontSize={0} mb={2}>
        Canvas
      </H5>
      <Flex py={1} alignItems="center">
        <Label>Text</Label>
        <Input
          type="text"
          width={1}
          mr={2}
          value={container.text}
          onChange={changeContainer('text')}
        />
      </Flex>
      <Color
        label="Background"
        value={container.backgroundColor || '#fff'}
        onChange={changeContainer('backgroundColor')}
        showLock={false}
        width={128}
      />
      <SectionBorder my={4} />
      <H5 fontSize={0} mb={2}>
        Typography
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontFamily}
          onClick={() => onPin('fontFamily', value.fontFamily)}
        />
        <Label>Font Family</Label>
        <Input
          width={1}
          value={value.fontFamily}
          type="text"
          onChange={changeValue('fontFamily')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize}
          onClick={() => onPin('fontSize', value.fontSize)}
        />
        <Label>Font Size</Label>
        <Input
          min={0}
          max={256}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize)}
          type="range"
          onChange={changeValue('fontSize')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontWeight}
          onClick={() => onPin('fontWeight', value.fontWeight)}
        />
        <Label>Font Weight</Label>
        <select value={value.fontWeight} onChange={changeValue('fontWeight')}>
          <option>100</option>
          <option>200</option>
          <option>300</option>
          <option>400</option>
          <option>500</option>
          <option>600</option>
          <option>700</option>
          <option>800</option>
          <option>900</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textTransform}
          onClick={() => onPin('textTransform', value.textTransform)}
        />
        <Label>Text Transform</Label>
        <select
          value={value.textTransform}
          onChange={changeValue('textTransform')}
        >
          <option>capitalize</option>
          <option>uppercase</option>
          <option>lowercase</option>
          <option>none</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textAlign}
          onClick={() => onPin('textAlign', value.textAlign)}
        />
        <Label>Text Align</Label>
        <select value={value.textAlign} onChange={changeValue('textAlign')}>
          <option>left</option>
          <option>right</option>
          <option>center</option>
          <option>justify</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontStyle}
          onClick={() => onPin('fontStyle', value.fontStyle)}
        />
        <Label>Font Style</Label>
        <select value={value.fontStyle} onChange={changeValue('fontStyle')}>
          <option>normal</option>
          <option>italic</option>
          <option>oblique</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeight}
          onClick={() => onPin('lineHeight', value.lineHeight)}
        />
        <Label>Line Height</Label>
        <Input
          min={0}
          max={4}
          step={0.1}
          width={1}
          mr={2}
          value={Number(value.lineHeight)}
          type="range"
          onChange={changeValue('lineHeight')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          lineHeight={0}
          children={value.lineHeight}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.letterSpacing}
          onClick={() => onPin('letterSpacing', value.letterSpacing)}
        />
        <Label>Letter Spacing</Label>
        <Input
          min={-10}
          max={10}
          step={0.1}
          width={1}
          mr={2}
          value={Number(value.letterSpacing)}
          type="range"
          onChange={changeValue('letterSpacing')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          letterSpacing={0}
          children={value.letterSpacing}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          '.text-gradient {\n  ' +
          'background-image: ' +
          randomGradient.toBackgroundImage(value) +
          ';\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}'
        }
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const TextGradient = styled('div')({\n" +
          `  backgroundImage: '${randomGradient.toBackgroundImage(value)}',` +
          "\n  WebkitBackgroundClip: 'text'," +
          "\n  WebkitTextFillColor: 'transparent'" +
          '\n})\n'
        }
      />
    </>
  )
}

export default ({ initialGradient, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textGradientVotes'))
  } catch (e) {}

  const [gradient, setGradient] = useState(initialGradient || randomGradient())
  const [container, setContainer] = useState({ text: 'Rad Sparkle' })
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const gradientWithPins = { ...gradient, ...pins }
  const fullGradient = {
    lineHeight: 1.2,
    letterSpacing: 0,
    fontSize: 100,
    fontWeight: 800,
    fontFamily: 'system-ui, sans-serif',
    textTransform: 'none',
    textAlign: 'center',
    ...gradientWithPins,
    backgroundColor: gradientWithPins.startColor,
    backgroundImage: randomGradient.toBackgroundImage(gradientWithPins)
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textGradients')
      .collection('votes')
      .add({
        up: true,
        data: fullGradient,
        metadata: { pins, container }
      })

    localStorage.setItem(
      'textGradientVotes',
      JSON.stringify(
        [
          {
            id,
            backgroundColor: fullGradient.backgroundColor,
            backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setGradient(randomGradient())
  }
  const skip = () => {
    setGradient(randomGradient())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = gradient => {
    setGradient(gradient)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main gradient={fullGradient} container={container} />
        )
      }
      sidebar={
        <Sidebar
          value={fullGradient}
          pins={pins}
          container={container}
          onChangeContainer={(key, value) => {
            setContainer({
              ...container,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onChange={(key, value) => {
            setGradient({
              ...gradient,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/text-gradients/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton
              disabled={gridView}
              css={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
